header
	position: fixed
	width: 100%
	padding: 25px 0
	z-index: 999
	background: $accentalt
	.wrapper
		+container 
		display: flex
		justify-content: space-between
		align-items: center
		>a
			+links
			>svg
				display: block
				height: 35px
				width: auto
		>ul
			display: flex
			gap: 25px
			align-items: center
			li
				a
					+links