@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  background: #EDE8E6;
  color: #2D2B2C;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
}

body hr {
  border: none;
  border-bottom: 1px solid #2D2B2C;
  display: block;
}

body * {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  list-style: none;
}

body * ::-moz-selection {
  color: #F3F3F3;
  background: #2D2B2C;
}

body * ::selection {
  color: #F3F3F3;
  background: #2D2B2C;
}

body header {
  position: fixed;
  width: 100%;
  padding: 25px 0;
  z-index: 999;
  background: #E4DFDC;
}

body header .wrapper {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body header .wrapper > a {
  text-decoration: none;
  color: inherit;
}

body header .wrapper > a > svg {
  display: block;
  height: 35px;
  width: auto;
}

body header .wrapper > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 25px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body header .wrapper > ul li a {
  text-decoration: none;
  color: inherit;
}

@-webkit-keyframes herolines {
  0% {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
  50% {
    -webkit-transform: translate(-80vh, -50%);
            transform: translate(-80vh, -50%);
  }
  100% {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

@keyframes herolines {
  0% {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
  50% {
    -webkit-transform: translate(-80vh, -50%);
            transform: translate(-80vh, -50%);
  }
  100% {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

body .jp__hero {
  min-height: 100vh;
  background: #E4DFDC;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

body .jp__hero .wrapper {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.4fr 1fr;
      grid-template-columns: 1.4fr 1fr;
  grid-gap: 80px;
  padding: 100px 0 40px;
  width: 100%;
}

body .jp__hero .wrapper .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

body .jp__hero .wrapper .left .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  max-width: 250px;
}

body .jp__hero .wrapper .left .info p {
  font-family: "Inter", sans-serif;
  font-size: calc(14px + 2 * ( (100vw - 575px) / 1155));
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400;
  color: #2D2B2C;
}

@media screen and (max-width: 575px) {
  body .jp__hero .wrapper .left .info p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1730px) {
  body .jp__hero .wrapper .left .info p {
    font-size: 16px;
  }
}

body .jp__hero .wrapper .left .title .desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 15px;
  max-width: 350px;
  margin-left: auto;
  -webkit-transform: translate(-25%, 96%);
          transform: translate(-25%, 96%);
}

body .jp__hero .wrapper .left .title .desc h2 {
  font-family: "DM Sans", sans-serif;
  font-size: calc(20px + 10 * ( (100vw - 575px) / 1155));
  line-height: 1.3;
  font-weight: 500;
  color: #2D2B2C;
}

@media screen and (max-width: 575px) {
  body .jp__hero .wrapper .left .title .desc h2 {
    font-size: 20px;
  }
}

@media screen and (min-width: 1730px) {
  body .jp__hero .wrapper .left .title .desc h2 {
    font-size: 30px;
  }
}

body .jp__hero .wrapper .left .title .desc p {
  font-family: "Inter", sans-serif;
  font-size: calc(14px + 2 * ( (100vw - 575px) / 1155));
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400;
  color: #2D2B2C;
}

@media screen and (max-width: 575px) {
  body .jp__hero .wrapper .left .title .desc p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1730px) {
  body .jp__hero .wrapper .left .title .desc p {
    font-size: 16px;
  }
}

body .jp__hero .wrapper .left .title > svg {
  display: block;
  height: auto;
  width: 100%;
}

body .jp__hero .wrapper .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

body .jp__hero .wrapper .right .flow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

body .jp__hero .wrapper .right .flow > svg {
  display: block;
  height: 80%;
  width: auto;
  z-index: 1;
  -webkit-transform: translateZ(100px) scale(0.8);
          transform: translateZ(100px) scale(0.8);
}

body .jp__hero .wrapper .right .flow .lines_wrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

body .jp__hero .wrapper .right .flow .lines_wrap > svg {
  position: absolute;
  display: block;
  height: auto;
  width: 100vw;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 0;
  -webkit-animation: herolines 60s linear;
          animation: herolines 60s linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

body .jp__hero .wrapper .right hr {
  margin: 20px 0 15px;
}

body .jp__hero .wrapper .right .bot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .jp__hero .wrapper .right .bot ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

body .jp__hero .wrapper .right .bot ul li svg {
  display: block;
  height: 20px;
  width: auto;
}

body .jp__hero .wrapper .right .bot p {
  font-family: "Inter", sans-serif;
  font-size: calc(14px + 2 * ( (100vw - 575px) / 1155));
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400;
  color: #2D2B2C;
}

@media screen and (max-width: 575px) {
  body .jp__hero .wrapper .right .bot p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1730px) {
  body .jp__hero .wrapper .right .bot p {
    font-size: 16px;
  }
}
