@mixin ftype($min-font-size: 14px, $max-font-size: 200px, $lower-range: 575px, $upper-range: 1730px)

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}))

  @media screen and (max-width: $lower-range)
    font-size: $min-font-size

  @media screen and (min-width: $upper-range)
    font-size: $max-font-size


@mixin h1
  font-family: $font_header
  +ftype(25px,50px)
  line-height: 1.3
  font-weight: 500
  color: $black

@mixin h2
  font-family: $font_header
  +ftype(22px,40px)
  line-height: 1.3
  font-weight: 500
  color: $black

@mixin h3
  font-family: $font_header
  +ftype(20px,30px)
  line-height: 1.3
  font-weight: 500
  color: $black

@mixin h4
  font-family: $font_header
  +ftype(20px,30px)
  line-height: 1.3
  font-weight: 500
  color: $black
  text-transform: uppercase
  
@mixin h5
  font-family: $font_copy
  +ftype(20px,25px)
  line-height: 1.3
  font-weight: 500
  color: $black
  text-transform: uppercase
  
@mixin h6
  font-family: $font_copy
  +ftype(18px,20px)
  line-height: 1.3
  font-weight: 400
  color: $black
  text-transform: uppercase

@mixin p1
  font-family: $font_copy
  +ftype(14px,16px)
  line-height: 1.5
  letter-spacing: 0
  font-weight: 400
  color: $black

@mixin p2
  font-family: $font_copy
  +ftype(14px,14px)
  line-height: 1.5
  letter-spacing: 0
  font-weight: 400
  color: $black

@mixin caption
  font-family: $font_copy
  +ftype(14px,14px)
  line-height: 1.3
  letter-spacing: 6%
  font-weight: 500
  text-transform: uppercase
  color: $black
