@mixin fullbg($color)
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  background: $color

@mixin links
  text-decoration: none
  color: inherit

@mixin container($size: 1400px)
  display: block
  width: 100%
  max-width: $size
  margin: 0 auto

@mixin drop_shadow
  box-shadow: 0 6px 30px 0 #0000001c

@mixin hover_line($color: $black, $position: relative, $padd: 10px)
  padding-bottom: $padd
  position: $position
  &:before
    content: ''
    position: absolute
    bottom: 0
    left: 0
    height: 2px
    width: 0%
    transition: width .5s cubic-bezier(0.77,0,0.18,1)
    background: $color
  &:hover
    &:before
      width: 100%
