$accent: #EDE8E6
$accentalt: #E4DFDC
$black: #2D2B2C
$white: #F3F3F3

$font_header: 'DM Sans', sans-serif
$font_copy: 'Inter', sans-serif

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@400;500;600;700;800;900&display=swap');

body
	margin: 0
	background: $accent
	color: $black
	overflow-x: hidden
	font-family: $font_copy
	
	hr
		border: none
		border-bottom: 1px solid $black
		display: block
	
	*
		margin: 0
		padding: 0
		box-sizing: border-box
		outline: none
		list-style: none
		& ::selection
			color: $white
			background: $black
	
	@import -Global/mixins
	@import -Global/type
	
	@import Header/Header
	
	@import Pagehome/Hero/Hero