@keyframes herolines
	0%
		transform: translate(0,-50%)
	50%
		transform: translate(-80vh,-50%)
	100%
		transform: translate(0,-50%)

.jp__hero
	min-height: 100vh
	background: $accentalt
	display: flex
	
	.wrapper
		+container
		display: grid
		grid-template-columns: 1.4fr 1fr
		grid-gap: 80px
		padding: 100px 0 40px
		width: 100%
	
		
	
		.left
			display: flex
			flex-direction: column
			justify-content: space-between
			.info
				display: flex
				flex-direction: column
				gap: 10px
				max-width: 250px
				p
					+p1
			.title
				.desc
					display: flex
					flex-direction: column
					gap: 15px
					max-width: 350px
					margin-left: auto
					transform: translate(-25%,96%)
					h2
						+h3
					p
						+p1
				>svg
					display: block
					height: auto
					width: 100%
		.right
			display: flex
			flex-direction: column
			overflow: hidden
			.flow
				flex-grow: 1
				display: flex
				justify-content: center
				align-items: center
				position: relative
				transform-style: preserve-3d
					
				>svg
					display: block
					height: 80%
					width: auto
					z-index: 1
					transform: translateZ(100px) scale(0.8)
					
				.lines_wrap
					overflow: hidden
					position: absolute
					top: 0
					left: 0
					height: 100%
					width: 100%
					>svg
						position: absolute
						display: block
						height: auto
						width: 100vw
						top: 50%
						left: 0
						transform: translate(0,-50%)
						z-index: 0
						animation: herolines 60s linear
						animation-iteration-count: infinite
			hr
				margin: 20px 0 15px
			.bot
				display: flex
				justify-content: space-between
				align-items: center
				ul
					display: flex
					align-items: center
					gap: 8px
					
					li
						svg
							display: block
							height: 20px
							width: auto
				p
					+p1


	